import { DOMAIN_TYPE_CLIENT_NOTIFICATION } from '../../consts';
import { mmUid } from 'mm-ts-utils';
/**
 * DRY
 * @param id
 * @param payload
 */
export var clientNotification = function (id, payload) { return ({
    id: id,
    type: DOMAIN_TYPE_CLIENT_NOTIFICATION,
    attributes: {
        id: id,
        uid: mmUid(),
        payload: payload,
        created: new Date(),
    },
}); };
