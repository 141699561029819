import { __awaiter, __generator } from "tslib";
import { confirmModal } from '../../../client/src/modules/domain/app-modal';
export var onCloseHandler = function (dispatch, closeOrRestartConversation) { return __awaiter(void 0, void 0, void 0, function () {
    var confirmClose;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                confirmClose = function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        return [2 /*return*/, confirmModal(dispatch, {
                                title: 'Ukončit Chat',
                                body: 'Přejete si ukončit chat?',
                                labelOK: 'Ano',
                                labelCancel: 'Ne',
                            })];
                    });
                }); };
                return [4 /*yield*/, confirmClose()];
            case 1:
                if (_a.sent()) {
                    return [2 /*return*/, closeOrRestartConversation()];
                }
                return [2 /*return*/];
        }
    });
}); };
