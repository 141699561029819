import { __assign, __extends } from "tslib";
import { mmParseBoolean } from 'mm-ts-utils';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { ChatMessageType } from '../../../../_library/domain-base/BaseChatMessage';
import { getLoadableComponent } from '../../../../_library/utils/get-loadable-component';
import { CHAT_MESSAGE_DISPLAY_CONTEXT_DEFAULT } from '../../../../consts';
import { ErrorBoundary } from '../../../_core/components/ErrorBoundary';
import { config } from '../../../_core/config';
import { url } from '../../../_core/url';
import { createDebugLogger } from '../../../_core/utils/app-utils';
import { I18nMsgNs } from '../../../_core/utils/i18n-helpers';
import { ChatMessage } from '../../ChatMessage';
import './Messages.scss';
// toto je trosku hack... ide o to, ze widgety chceme typicky zobrazovat v rovnakom
// kontexte ako bezny text message (paddingy a pod...) lenze za istych okolnosti
// nemusime este mat styly pre TextMessage natiahnute (v pripade, ze prvy message
// je widget...), nizsie nie je najkrajsie ale riesi situaciu (druha moznost by bola
// includovat tento style v kazdom widgete co je nachylne na sklerozu)
import './TextMessage.scss';
import { WidgetWrapper } from './WidgetWrapper';
var Msg = I18nMsgNs('Messages');
var MessagesClass = /** @class */ (function (_super) {
    __extends(MessagesClass, _super);
    function MessagesClass() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.debug = createDebugLogger('Messages');
        return _this;
    }
    MessagesClass.getMessageComponent = function (message, cmpProps) {
        var Cmp;
        var type = message.type, id = message.id;
        cmpProps.message = message;
        cmpProps.key = cmpProps.key || id;
        if (type === ChatMessageType.WIDGET ||
            type == ChatMessageType.NOTIFICATION ||
            type === ChatMessageType.END) {
            Cmp = React.createElement(WidgetWrapper, __assign({}, cmpProps));
        }
        // ChatMessageType.DEFAULT + catch all
        else {
            Cmp = getLoadableComponent('TextMessage', cmpProps);
        }
        return Cmp;
    };
    MessagesClass.prototype.render = function () {
        var _this = this;
        var _a = this.props, messages = _a.messages, identity = _a.identity;
        var B = config.css.B('-message-group');
        if (!messages) {
            return React.createElement(React.Fragment, null);
        }
        // group by "user" or "other"
        var _group;
        var groups = messages.reduce(function (acc, data, idx, src) {
            var _currentLabel = ChatMessage.resolveGroupLabel(data);
            if (!_group) {
                _group = { label: _currentLabel, messages: [] };
            }
            if (_group.label !== _currentLabel) {
                acc.push(_group);
                _group = _group = { label: _currentLabel, messages: [] };
            }
            _group.messages.push(data);
            // last round?
            if (idx === src.length - 1) {
                acc.push(_group);
            }
            return acc;
        }, []);
        var _messageRenderer = function (data, idx, gidx, groupLength) {
            var m = new ChatMessage(data);
            // old type, not used anymore...
            if (m.type === ChatMessageType.TRANSIENT_IS_TYPING) {
                return null;
            }
            if (m.type === ChatMessageType.HIDDEN) {
                return React.createElement(ErrorBoundary, { key: m.id }, null);
            }
            var cmpProps = {
                identity: identity,
                key: m.id,
                message: m,
                messages: messages,
                submitMessage: _this.props.submitMessage,
                scrollToBottom: _this.props.scrollToBottom,
                displayContextIndex: idx,
                displayContext: CHAT_MESSAGE_DISPLAY_CONTEXT_DEFAULT,
                //
                displayGroupContextIndex: gidx,
                displayGroupContextLength: groupLength,
            };
            var Cmp = MessagesClass.getMessageComponent(m, cmpProps);
            // prettier-ignore
            var _renderDebugMessageLink = function (m) {
                if (mmParseBoolean(process.env.SHOW_DEBUG_MSG_LINKS)) {
                    return React.createElement(Link, { to: url.MESSAGE(m.thread_id, m.id), style: { position: 'absolute', fontSize: '.9em', color: 'silver' } }, "\u2192");
                }
                return null;
            };
            // prettier-ignore
            return (React.createElement(ErrorBoundary, { key: m.id },
                React.createElement(CSSTransition, { classNames: "fade", timeout: { enter: 200, exit: 200 }, appear: true },
                    React.createElement(React.Fragment, null,
                        " ",
                        _renderDebugMessageLink(m),
                        " ",
                        Cmp,
                        " "))));
        };
        // };
        // !important to keep the message index "it the top context, above" group's message
        var idx = 0;
        return groups.map(function (group, gidx) {
            return (React.createElement("div", { key: "".concat(group.label).concat(gidx), className: B, "data-group-label": group.label }, group.messages.map(function (data, _gidx, arr) {
                return _messageRenderer(data, idx++, _gidx, arr.length);
            })));
        });
    };
    return MessagesClass;
}(React.Component));
export { MessagesClass };
// update: nakoniec toto netreba...
// const withSizeHOC = withSize({ monitorHeight: true });
// export const Messages = withSizeHOC(MessagesClass);
export var Messages = MessagesClass;
export default Messages;
