import { __assign } from "tslib";
import produce from 'immer';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import merge from 'lodash-es/merge';
import omit from 'lodash-es/omit';
import { mmUid } from 'mm-ts-utils';
import { ChatMessageType } from '../../_library/domain-base/BaseChatMessage';
import { ARBITRARY_ID_BUBBLE_UI_STATE, ARBITRARY_ID_INITIAL_DATA, ARBITRARY_ID_UI_STATE, DOMAIN_TYPE_ARBITRARY, DOMAIN_TYPE_CHAT_MESSAGE, DOMAIN_TYPE_CHAT_THREAD, DOMAIN_TYPE_CLIENT_NOTIFICATION, DOMAIN_TYPE_OPPOSITE_PARTY, DOMAIN_TYPE_USER, GOODBOT_USER_ID, NOTIFICATION_ID__THREAD_IS_TYPING, } from '../../consts';
import { isInIframe } from '../_core/utils/app-utils';
import { getAppModal } from './selectors/get-app-modal';
import { getByTypeAndId } from './selectors/get-by-type-and-id';
import { getClientNotification } from './selectors/get-client-notification';
import { getInitialData } from './selectors/get-initial-data';
import { getLastThreadMessage } from './selectors/get-last-thread-message';
import { getMessage } from './selectors/get-message';
import { getOppositeParty } from './selectors/get-opposite-party';
import { getThread } from './selectors/get-thread';
import { getThreadMessage } from './selectors/get-thread-message';
import { getThreadMessages } from './selectors/get-thread-messages';
import { getUIState } from './selectors/get-ui-state';
import { getUser } from './selectors/get-user';
import { getBubbleUIState } from './selectors/get-bubble-ui-state';
import { getMeta } from './selectors/get-meta';
/*********************************************************************************
 * ACTION TYPES
 ********************************************************************************/
export var domainActionType = {
    MERGE_MODELS_PAYLOAD: 'domain/MERGE_MODELS_PAYLOAD',
    DELETE_MODEL: 'domain/DELETE_MODEL',
    DELETE_MODELS: 'domain/DELETE_MODELS',
    UPDATE_MODEL: 'domain/UPDATE_MODEL',
    UPDATE_MODELS: 'domain/UPDATE_MODELS',
    SET_OPPOSITE_PARTY: 'domain/SET_OPPOSITE_PARTY',
    RESET_ENTITY_TYPE: 'domain/RESET_ENTITY_TYPE',
    SET_WAITING: 'domain/SET_WAITING',
    CLEAR_WAITING: 'domain/CLEAR_WAITING',
};
/*********************************************************************************
 * ACTIONS (ACTION CREATORS)
 ********************************************************************************/
export var domainAction = {
    /**
     * @param normalizedDomainPayload
     */
    mergeModelsPayload: function (normalizedDomainPayload) { return ({
        type: domainActionType.MERGE_MODELS_PAYLOAD,
        payload: normalizedDomainPayload,
    }); },
    /**
     * @param entityType
     * @param id
     */
    deleteModelByEntityTypeAndId: function (entityType, id) { return ({
        type: domainActionType.DELETE_MODEL,
        payload: { entityType: entityType, id: id },
    }); },
    /**
     * @param payload
     */
    deleteDomainModels: function (payload) { return ({
        type: domainActionType.DELETE_MODELS,
        payload: payload,
    }); },
    /**
     * @param entityType
     * @param id
     * @param data
     */
    updateDomainModel: function (entityType, id, data) { return ({
        type: domainActionType.UPDATE_MODEL,
        payload: { entityType: entityType, id: id, data: data },
    }); },
    /**
     * update bude updatovat iba existujuce
     * @param normalizedDomainPayload
     */
    updateDomainModelsPayload: function (normalizedDomainPayload) { return ({
        type: domainActionType.UPDATE_MODELS,
        payload: normalizedDomainPayload,
    }); },
    /**
     * @param entityType
     */
    resetDomainModelEnityType: function (entityType) { return ({
        type: domainActionType.RESET_ENTITY_TYPE,
        payload: entityType,
    }); },
    setDomainOppositeParty: function (isAgent) { return ({
        type: domainActionType.SET_OPPOSITE_PARTY,
        payload: isAgent,
    }); },
    setWaiting: function (threadId) { return ({
        type: domainActionType.SET_WAITING,
        payload: threadId,
    }); },
    clearWaiting: function () { return ({
        type: domainActionType.CLEAR_WAITING,
    }); },
};
/*********************************************************************************
 * SELECTORS
 ********************************************************************************/
export var domainSelector = {
    getThread: getThread,
    getClientNotification: getClientNotification,
    getOppositeParty: getOppositeParty,
    getThreadMessages: getThreadMessages,
    getThreadMessage: getThreadMessage,
    getLastThreadMessage: getLastThreadMessage,
    getUser: getUser,
    getMessage: getMessage,
    getByTypeAndId: getByTypeAndId,
    getInitialData: getInitialData,
    getUIState: getUIState,
    getAppModal: getAppModal,
    getMeta: getMeta,
    getBubbleUIState: getBubbleUIState,
};
var createInitialDomainState = function () {
    var _a, _b;
    return (_a = {},
        _a[DOMAIN_TYPE_ARBITRARY] = (_b = {},
            _b[ARBITRARY_ID_INITIAL_DATA] = __assign(__assign({}, (window.__GAID_INITIAL_DATA__ || {})), { id: ARBITRARY_ID_INITIAL_DATA, __clientIsTopWindow__: !isInIframe(), __clientLocationHref__: window.location.href, __clientUserAgent__: navigator.userAgent }),
            _b[ARBITRARY_ID_UI_STATE] = {
                id: ARBITRARY_ID_UI_STATE,
                chatBubbleTypingIndicatorHidden: true,
                isBubbleOpen: false,
            },
            _b[ARBITRARY_ID_BUBBLE_UI_STATE] = {
                id: ARBITRARY_ID_BUBBLE_UI_STATE,
            },
            _b),
        _a[DOMAIN_TYPE_OPPOSITE_PARTY] = false,
        _a);
};
export var domainReducer = produce(function (state, action) {
    if (action.type.endsWith('_REJECTED_MARK')) {
        return _addErrorMessage(state, action.payload);
    }
    switch (action.type) {
        case domainActionType.MERGE_MODELS_PAYLOAD:
            return _mergeModelsPayload(state, action.payload);
        case domainActionType.DELETE_MODEL:
            return _deleteModel(state, action.payload);
        case domainActionType.DELETE_MODELS:
            return _deleteModels(state, action.payload);
        case domainActionType.UPDATE_MODELS:
            return _mergeModelsPayload(state, action.payload, true);
        case domainActionType.UPDATE_MODEL:
            return _updateModel(state, action.payload);
        case domainActionType.RESET_ENTITY_TYPE:
            return omit(state, [action.payload]);
        case domainActionType.SET_OPPOSITE_PARTY:
            return _setOppositeParty(state, action.payload);
        case domainActionType.SET_WAITING:
            return _displayWaiting(state, action.payload);
        case domainActionType.CLEAR_WAITING: {
            return _clearWaiting(state);
        }
    }
}, createInitialDomainState());
/*********************************************************************************
 * HELPERS
 ********************************************************************************/
function _addErrorMessage(state, actionPayload) {
    var messageId = mmUid();
    var error = actionPayload.error, threadId = actionPayload.threadId;
    state[DOMAIN_TYPE_CHAT_MESSAGE][messageId] = {
        id: messageId,
        user_id: undefined,
        thread_id: threadId,
        is_bot: false,
        type: ChatMessageType.TEXT,
        body: "Sorry, unexpected error happened. Please, report the problem with the following details. ".concat(error),
        created: new Date(),
        meta: {},
    };
}
function _displayWaiting(state, threadId) {
    var messageId = mmUid();
    state[DOMAIN_TYPE_CHAT_MESSAGE][messageId] = {
        id: messageId,
        user_id: GOODBOT_USER_ID,
        thread_id: threadId,
        type: ChatMessageType.WIDGET,
        body: 'Waiting',
        created: new Date(),
        updated: null,
    };
}
function _clearWaiting(state) {
    var waitingMessageId = Object.keys(state[DOMAIN_TYPE_CHAT_MESSAGE]).filter(function (a) {
        return state[DOMAIN_TYPE_CHAT_MESSAGE][a].body === 'Waiting' &&
            state[DOMAIN_TYPE_CHAT_MESSAGE][a].type === ChatMessageType.WIDGET;
    })[0];
    if (waitingMessageId) {
        delete state[DOMAIN_TYPE_CHAT_MESSAGE][waitingMessageId];
    }
}
/**
 * @param state
 * @param normalizedDomainPayload
 * @param updateOnly
 * @private
 */
var _mergeModelsPayload = function (state, normalizedDomainPayload, updateOnly) {
    if (updateOnly === void 0) { updateOnly = false; }
    // tu nam uz __meta__ zavadza... ak sme ju na nieco potrebovali, musime to uz mat hotove...
    // note: umyselne nerobim delete o.__meta__ lebo raw payload mame ulozeny aj inde
    // a kedze mame referenciu, deletli by sme to aj tam...
    normalizedDomainPayload = omit(normalizedDomainPayload, ['__meta__']);
    // return early untouched ak nic...
    if (isEmpty(normalizedDomainPayload)) {
        return;
    }
    Object.keys(normalizedDomainPayload).forEach(function (entityType) {
        // state[entityType] = merge(state[entityType] || {}, normalizedDomainPayload[entityType]);
        state[entityType] = state[entityType] || {};
        Object.keys(normalizedDomainPayload[entityType]).forEach(function (id) {
            // ak updatujeme a cielovy model v store neexistuje, tak nic...
            if (updateOnly && !state[entityType][id]) {
                return;
            }
            if (!state[entityType][id] ||
                !isEqual(state[entityType][id], normalizedDomainPayload[entityType][id])) {
                // state[entityType][id] = normalizedDomainPayload[entityType][id];
                // NOTE: nizsi merge je extremne DOLEZITY, lebo pri mergovani payloadu,
                // moze dochadzat k odchylke, ze normalizovany payload neobsahuje
                // `relations` ak source je z `included`... a tento merge ak source
                // ma undefined kluce, tak ponechava hodnotu... UFF...
                state[entityType][id] = merge(state[entityType][id] || {}, normalizedDomainPayload[entityType][id]);
            }
        });
    });
};
/**
 * @param state
 * @param entityType
 * @param id
 * @private
 */
var _deleteModel = function (state, _a) {
    var _b;
    var entityType = _a.entityType, id = _a.id;
    if ((_b = state[entityType]) === null || _b === void 0 ? void 0 : _b[id]) {
        delete state[entityType][id];
    }
};
/**
 * @param state
 * @param models
 * @private
 */
var _deleteModels = function (state, models) {
    var _a;
    for (var _i = 0, models_1 = models; _i < models_1.length; _i++) {
        var model = models_1[_i];
        var id = model.id, type = model.type;
        if ((_a = state[type]) === null || _a === void 0 ? void 0 : _a[id]) {
            delete state[type][id];
        }
    }
};
/**
 * @param state
 * @param entityType
 * @param id
 * @param data
 * @private
 */
var _updateModel = function (state, _a) {
    var _b;
    var entityType = _a.entityType, id = _a.id, data = _a.data;
    var entity = (_b = state[entityType]) === null || _b === void 0 ? void 0 : _b[id];
    if (!entity) {
        return;
    }
    for (var _i = 0, _c = Object.entries(data || {}); _i < _c.length; _i++) {
        var _d = _c[_i], k = _d[0], o = _d[1];
        if (!isEqual(o, entity[k])) {
            state[entityType][id][k] = o;
        }
    }
};
/**
 * @param state
 * @param isAgent
 * @private
 */
var _setOppositeParty = function (state, isAgent) {
    state[DOMAIN_TYPE_OPPOSITE_PARTY] = isAgent;
};
