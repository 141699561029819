import { __awaiter, __generator } from "tslib";
import { mmUid } from 'mm-ts-utils';
import { AGENT_USER_ID, DOMAIN_TYPE_CHAT_MESSAGE, GOODBOT_USER_ID, NOTIFICATION_ID__THREAD_CHANGE, NOTIFICATION_ID__THREAD_CREATED, } from '../../client/src/consts';
import { ChatMessage } from '../../client/src/modules/chat/ChatMessage';
import { clientNotification } from '../../client/src/modules/domain/domain-helpers';
import { apiNormalizeJsonApiResponse } from '../../client/src/modules/_core/utils/api-helpers';
import { generateNewThreadId } from '../../client/src/_library/utils/identifiers';
var FrontstageAdapter = /** @class */ (function () {
    function FrontstageAdapter(httpClient) {
        var _this = this;
        this.httpClient = httpClient;
        this.timestamp = 0;
        this.readThread = function (threadId) { return __awaiter(_this, void 0, void 0, function () {
            var messages;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchMessages(threadId)];
                    case 1:
                        messages = _a.sent();
                        return [2 /*return*/, apiNormalizeJsonApiResponse({
                                data: messages,
                                included: [
                                    clientNotification(NOTIFICATION_ID__THREAD_CHANGE, {
                                        thread_id: threadId,
                                        created: new Date(),
                                    }),
                                ],
                            })];
                }
            });
        }); };
        this.fetchMessages = function (threadId) { return __awaiter(_this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient.get("/conversations/".concat(threadId, "/message/").concat(this.timestamp), {
                            'X-Interaction-Id': mmUid(),
                        })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.Messages.map(this.toChatMessage.bind(this, threadId))];
                }
            });
        }); };
        this.createThread = function (id, api_token, options) { return __awaiter(_this, void 0, void 0, function () {
            var location, response, messages, threadId;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        try {
                            location = window.top.document.location.href;
                        }
                        catch (_c) {
                            console.warn('Failed to fetch Location from (potentially CORS issue), defaulting to location defined in InitialData');
                            location = (_a = options.initialData) !== null && _a !== void 0 ? _a : '';
                        }
                        return [4 /*yield*/, this.httpClient.post("/conversation", {
                                Conversation: {
                                    Id: generateNewThreadId(),
                                    Status: ConversationStatus.CHATBOT,
                                },
                                namedEntities: {
                                    Location: location,
                                },
                            }, {
                                'X-Interaction-Id': mmUid(),
                            })];
                    case 1:
                        response = _b.sent();
                        messages = response.Messages;
                        threadId = response.Conversation.Id;
                        return [2 /*return*/, apiNormalizeJsonApiResponse({
                                data: messages.map(this.toChatMessage.bind(this, threadId)),
                                included: [
                                    clientNotification(NOTIFICATION_ID__THREAD_CREATED, {
                                        thread_id: threadId,
                                    }),
                                ],
                                meta: response.meta,
                            })];
                }
            });
        }); };
        this.saveMessage = function (messageData) { return __awaiter(_this, void 0, void 0, function () {
            var threadId, conversationId, result, messages, includedEntities;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        threadId = messageData.thread_id;
                        conversationId = threadId.split('|')[0];
                        return [4 /*yield*/, this.httpClient.post("/conversations/".concat(conversationId, "/message"), {
                                Message: {
                                    Type: 'Message',
                                    Message: messageData.body,
                                },
                            }, {
                                'X-Interaction-Id': messageData.id,
                            })];
                    case 1:
                        result = _a.sent();
                        messages = result.response.map(this.toChatMessage.bind(this, threadId));
                        includedEntities = [
                            new ChatMessage(messageData).toJSONApi(),
                            clientNotification(NOTIFICATION_ID__THREAD_CHANGE, {
                                thread_id: threadId,
                                created: new Date(),
                            }),
                        ];
                        return [2 /*return*/, apiNormalizeJsonApiResponse({
                                data: messages,
                                included: includedEntities,
                                meta: result.meta,
                            })];
                }
            });
        }); };
        this.httpClient = httpClient;
    }
    FrontstageAdapter.prototype.toChatMessage = function (threadId, message) {
        // __options__ is a separator between message body and option buttons (see frontstage-orchestration chabotService.convertToButtons)
        var body = message.Message.trim().split('__options__')[0];
        var options = message.Message.split('__options__')[1]
            ? JSON.parse(message.Message.split('__options__')[1])
            : [];
        var type = message.Type.toLowerCase();
        return {
            id: message.Id,
            type: DOMAIN_TYPE_CHAT_MESSAGE,
            attributes: {
                id: message.Id,
                user_id: FrontstageAdapter.getUserId(message),
                thread_id: threadId,
                is_bot: FrontstageAdapter.getUserId(message) === GOODBOT_USER_ID,
                type: type,
                body: type === 'widget' ? JSON.parse(body).name : body,
                created: new Date(Number(message.TimeStamp)),
                timestamp: Number(message.TimeStamp),
                meta: {
                    data: type === 'widget' ? JSON.parse(body) : body,
                    options: options,
                },
            },
        };
    };
    FrontstageAdapter.getUserId = function (message) {
        switch (message.UserId) {
            case MessageUserType.CHATBOT:
                return GOODBOT_USER_ID;
            case MessageUserType.AGENT:
                return AGENT_USER_ID;
            default:
                return undefined;
        }
    };
    return FrontstageAdapter;
}());
export default FrontstageAdapter;
var ConversationStatus;
(function (ConversationStatus) {
    ConversationStatus[ConversationStatus["CHATBOT"] = -1] = "CHATBOT";
    ConversationStatus[ConversationStatus["WAITING_FOR_AGENT"] = 0] = "WAITING_FOR_AGENT";
    ConversationStatus[ConversationStatus["ACTIVE_AGENT"] = 1] = "ACTIVE_AGENT";
    ConversationStatus[ConversationStatus["INACTIVE_AGENT"] = 2] = "INACTIVE_AGENT";
})(ConversationStatus || (ConversationStatus = {}));
export var MessageUserType;
(function (MessageUserType) {
    MessageUserType[MessageUserType["USER"] = 0] = "USER";
    MessageUserType[MessageUserType["AGENT"] = 1] = "AGENT";
    MessageUserType[MessageUserType["CHATBOT"] = 2] = "CHATBOT";
})(MessageUserType || (MessageUserType = {}));
