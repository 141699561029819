import { __awaiter, __generator } from "tslib";
import { cloneDeep } from 'lodash';
import { mmDelay, mmGetRandomInt } from 'mm-ts-utils';
import { BaseChatMessage, ChatMessageType, } from '../../../_library/domain-base/BaseChatMessage';
import { DOMAIN_TYPE_CHAT_MESSAGE, DOMAIN_TYPE_CLIENT_NOTIFICATION, GOODBOT_USER_ID, NOTIFICATION_ID__THREAD_IS_TYPING, } from '../../../consts';
import { api } from '../../_core/utils/api-helpers';
import { getClientConfig } from '../../_core/utils/app-storage';
import { createDebugLogger } from '../../_core/utils/app-utils';
import { pmaMarkFulfilled, pmaMarkRejected, pmaResetExistingMark, } from '../../_core/utils/pma-reducer';
import { domainAction, domainSelector } from '../../domain/domain-redux';
import { dispatchUiState } from '../../domain/ui-state';
import { chatActionType } from '../chat-redux';
import { _detectOppositeParty } from './_detect-opposite-party';
var debug = createDebugLogger('save-message');
export var saveMessage = function (identity, message) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var messageData, id, threadId, type, rootState, clientConfig, messages, oppositeParty, doIndicateIsTyping, isNotification, isEnd, isWidget, isTypingDescription, HIDE_CHAT_INPUT_ON_MESSAGE_SUBMIT_TO_GB, DISABLE_CHAT_INPUT_ON_MESSAGE_SUBMIT_TO_GB, HIDE_CHAT_INPUT_IN_WIDGETS, didManipulateUIState, options, threadData_1, setWaitingDate_1, showWaitingInterval_1, _intervalledDispatch, value_1, oldIds_1, sortByDateAsc_1, _a, oldMessages, newMessages, domainPayload, getWait, getDuration, _i, _b, _c, index, m, isFirst, isLast, e_1;
    var _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                messageData = message.toJSON();
                // 1. optimistic client add first...
                dispatch(domainAction.mergeModelsPayload((_d = {},
                    _d[message.entityType] = (_e = {}, _e[message.id] = messageData, _e),
                    _d)));
                id = messageData.id;
                threadId = messageData.thread_id;
                type = chatActionType.FETCH_SAVE_MESSAGE;
                rootState = getState();
                clientConfig = getClientConfig();
                messages = domainSelector.getThreadMessages(rootState)(threadId);
                oppositeParty = _detectOppositeParty(messages);
                doIndicateIsTyping = oppositeParty === BaseChatMessage.AUTHOR_TYPE_BOT;
                isNotification = messageData.type === ChatMessageType.NOTIFICATION;
                isEnd = messageData.type === ChatMessageType.END;
                isWidget = messageData.type === ChatMessageType.WIDGET || isNotification;
                // lukas, nizsie je rizikove, lebo nikde nie je garantovane, ze saveMessage
                // je vzdy posledny (pred odoslanim sa mohli messages zmenit napr. via websocket)
                // const isWidget = messages[messages.length - 1].type === 'widget';
                debug("Chatting with ".concat(oppositeParty, "; do indicate: ").concat(doIndicateIsTyping, "..."));
                isTypingDescription = _maybeStartIsTyping({
                    threadId: threadId,
                    dispatch: dispatch,
                    clientConfig: clientConfig,
                    doIndicateIsTyping: doIndicateIsTyping,
                });
                HIDE_CHAT_INPUT_ON_MESSAGE_SUBMIT_TO_GB = clientConfig.HIDE_CHAT_INPUT_ON_MESSAGE_SUBMIT_TO_GB, DISABLE_CHAT_INPUT_ON_MESSAGE_SUBMIT_TO_GB = clientConfig.DISABLE_CHAT_INPUT_ON_MESSAGE_SUBMIT_TO_GB, HIDE_CHAT_INPUT_IN_WIDGETS = clientConfig.HIDE_CHAT_INPUT_IN_WIDGETS;
                didManipulateUIState = false;
                if (oppositeParty === BaseChatMessage.AUTHOR_TYPE_BOT &&
                    (HIDE_CHAT_INPUT_ON_MESSAGE_SUBMIT_TO_GB ||
                        DISABLE_CHAT_INPUT_ON_MESSAGE_SUBMIT_TO_GB)) {
                    didManipulateUIState = true;
                    dispatchUiState(dispatch, {
                        chat_input_hidden: !!HIDE_CHAT_INPUT_ON_MESSAGE_SUBMIT_TO_GB,
                        chat_input_disabled: !!DISABLE_CHAT_INPUT_ON_MESSAGE_SUBMIT_TO_GB,
                    });
                }
                if (HIDE_CHAT_INPUT_IN_WIDGETS && isWidget) {
                    didManipulateUIState = true;
                }
                options = {
                    initialData: domainSelector.getInitialData(rootState) || {},
                };
                _g.label = 1;
            case 1:
                _g.trys.push([1, 11, , 12]);
                threadData_1 = domainSelector.getThread(getState())(message.thread_id);
                dispatch(pmaResetExistingMark(type, id));
                showWaitingInterval_1 = setTimeout(function () {
                    setWaitingDate_1 = new Date().getTime();
                    dispatch(domainAction.setWaiting(threadId));
                }, 200);
                _intervalledDispatch = function () { return __awaiter(void 0, void 0, void 0, function () {
                    var ret;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, dispatch({
                                    type: type,
                                    payload: {
                                        data: { id: id },
                                        promise: api().saveMessage(messageData, threadData_1, (identity || {}).api_token, options, dispatch),
                                    },
                                })];
                            case 1:
                                ret = _a.sent();
                                setWaitingDate_1 = new Date().getTime() - setWaitingDate_1;
                                clearInterval(showWaitingInterval_1);
                                return [2 /*return*/, ret];
                        }
                    });
                }); };
                return [4 /*yield*/, _intervalledDispatch()];
            case 2:
                value_1 = (_g.sent()).value;
                setTimeout(function () { return dispatch(domainAction.clearWaiting()); }, 200 - setWaitingDate_1 > 0 ? 200 - setWaitingDate_1 : 0);
                // isTyping dance PART 2
                return [4 /*yield*/, _maybeIsTypingTeardown(dispatch, isTypingDescription)];
            case 3:
                // isTyping dance PART 2
                _g.sent();
                dispatch(pmaMarkFulfilled(type, value_1, id));
                // currently we don't save notification widget on BE and we don't have to delete it.
                if (!isEnd && !isNotification) {
                    // tu musime trosku hackovat... ide o to, ze na urovni klienta sme uz
                    // vramci optimistic dat mohli davno message setnut s potencialne rozdielnym
                    // ideckom (nie kazdy adapter podporuje message id forwardovanie)... teda
                    // aby sa nam neduplikovali veci skusime tento optimisticky sposobeny nesulad
                    // upratat (ina moznost by bola optimistic updates vobec nepouzivat...)
                    if (value_1[DOMAIN_TYPE_CHAT_MESSAGE] &&
                        !value_1[DOMAIN_TYPE_CHAT_MESSAGE][id]) {
                        dispatch(domainAction.deleteModelByEntityTypeAndId(DOMAIN_TYPE_CHAT_MESSAGE, id));
                    }
                }
                if (!!isTypingDescription) return [3 /*break*/, 4];
                if (didManipulateUIState) {
                    dispatchUiState(dispatch, {
                        chat_input_hidden: false,
                        chat_input_disabled: false,
                    });
                }
                dispatch(domainAction.mergeModelsPayload(value_1));
                return [3 /*break*/, 10];
            case 4:
                if (!(!isEnd && !isNotification)) return [3 /*break*/, 10];
                oldIds_1 = messages.map(function (v) { return v.id; });
                sortByDateAsc_1 = function (a, b) {
                    return new Date(a.created).valueOf() - new Date(b.created).valueOf();
                };
                _a = Object.keys(value_1[DOMAIN_TYPE_CHAT_MESSAGE] || {}).reduce(function (memo, k) {
                    var msg = value_1[DOMAIN_TYPE_CHAT_MESSAGE][k];
                    if (!oldIds_1.includes(k) && k !== messageData.id) {
                        memo.newMessages.push(msg);
                        // asi je optimalnejsie toto robit na zaver iba raz, ale neriesim teraz
                        memo.newMessages.sort(sortByDateAsc_1);
                    }
                    else {
                        memo.oldMessages[msg.id] = msg;
                    }
                    return memo;
                }, { oldMessages: {}, newMessages: [] }), oldMessages = _a.oldMessages, newMessages = _a.newMessages;
                domainPayload = cloneDeep(value_1);
                // mergneme stare... toto ma zmysel ak ich nahodou server upravil (co sa teraz
                // sice nedeje, ale technicky sa moze /napr. sa zmenil interny stav widgetu ak
                // by to mal podporovat/)
                // zaroven tymto riesime vyssie mozno zmenene id aktualneho messagu
                domainPayload[DOMAIN_TYPE_CHAT_MESSAGE] = oldMessages;
                dispatch(domainAction.mergeModelsPayload(domainPayload));
                // teraz si vycistime a pojdeme na nove message v loope s delayom
                domainPayload[DOMAIN_TYPE_CHAT_MESSAGE] = {};
                getWait = isTypingDescription
                    ? isTypingDescription.getWait
                    : function () { return mmGetRandomInt(500, 1500); };
                getDuration = isTypingDescription
                    ? isTypingDescription.getDuration
                    : function () { return mmGetRandomInt(1500, 2500); };
                _i = 0, _b = Array.from(newMessages.entries());
                _g.label = 5;
            case 5:
                if (!(_i < _b.length)) return [3 /*break*/, 10];
                _c = _b[_i], index = _c[0], m = _c[1];
                isFirst = index === 0;
                if (!(!isFirst && m.body)) return [3 /*break*/, 8];
                return [4 /*yield*/, mmDelay(getWait())];
            case 6:
                _g.sent();
                _startIsTypingBot(dispatch, threadId);
                return [4 /*yield*/, mmDelay(getDuration())];
            case 7:
                _g.sent();
                _stopIsTypingBot(dispatch);
                _g.label = 8;
            case 8:
                isLast = index === newMessages.length - 1;
                if (isLast && didManipulateUIState) {
                    dispatchUiState(dispatch, {
                        chat_input_hidden: false,
                        chat_input_disabled: false,
                    });
                }
                domainPayload[DOMAIN_TYPE_CHAT_MESSAGE] = (_f = {}, _f[m.id] = m, _f);
                dispatch(domainAction.mergeModelsPayload(domainPayload));
                _g.label = 9;
            case 9:
                _i++;
                return [3 /*break*/, 5];
            case 10: return [3 /*break*/, 12];
            case 11:
                e_1 = _g.sent();
                console.error(e_1);
                dispatch(domainAction.clearWaiting());
                dispatch(pmaMarkRejected(type, e_1, threadId));
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}); }; };
var _startIsTypingBot = function (dispatch, threadId) {
    var _a, _b;
    dispatch(domainAction.mergeModelsPayload((_a = {},
        _a[DOMAIN_TYPE_CLIENT_NOTIFICATION] = (_b = {},
            _b[NOTIFICATION_ID__THREAD_IS_TYPING] = {
                payload: {
                    thread_id: threadId,
                    user_id: [GOODBOT_USER_ID],
                },
                created: new Date().toISOString(),
            },
            _b),
        _a)));
};
var _stopIsTypingBot = function (dispatch) {
    dispatch(domainAction.deleteModelByEntityTypeAndId(DOMAIN_TYPE_CLIENT_NOTIFICATION, NOTIFICATION_ID__THREAD_IS_TYPING));
};
/**
 * @param threadId
 * @param dispatch
 * @param clientConfig
 * @param oppositeParty
 * @private
 */
var _maybeStartIsTyping = function (_a) {
    var threadId = _a.threadId, dispatch = _a.dispatch, clientConfig = _a.clientConfig, doIndicateIsTyping = _a.doIndicateIsTyping;
    // ak je protistrana GB...
    if (!doIndicateIsTyping) {
        return null;
    }
    var IS_TYPING_INDICATOR_WAIT_MIN_MS = clientConfig.IS_TYPING_INDICATOR_WAIT_MIN_MS, IS_TYPING_INDICATOR_WAIT_MAX_MS = clientConfig.IS_TYPING_INDICATOR_WAIT_MAX_MS, IS_TYPING_INDICATOR_DURATION_MIN_MS = clientConfig.IS_TYPING_INDICATOR_DURATION_MIN_MS, IS_TYPING_INDICATOR_DURATION_MAX_MS = clientConfig.IS_TYPING_INDICATOR_DURATION_MAX_MS;
    if (IS_TYPING_INDICATOR_WAIT_MAX_MS === 0 ||
        IS_TYPING_INDICATOR_DURATION_MAX_MS === 0) {
        debug("IsTyping indicator disabled via config.");
        return null;
    }
    var STARTED = Date.now();
    var getWait = function () {
        return Math.abs(mmGetRandomInt(IS_TYPING_INDICATOR_WAIT_MIN_MS || 1000, IS_TYPING_INDICATOR_WAIT_MAX_MS || 1500));
    };
    var getDuration = function () {
        return Math.abs(mmGetRandomInt(IS_TYPING_INDICATOR_DURATION_MIN_MS || 3000, IS_TYPING_INDICATOR_DURATION_MAX_MS || 5000));
    };
    var WAIT = getWait();
    var DURATION = getDuration();
    // uistime sa uplne nezmysly nepovolime...
    if (isNaN(WAIT) || isNaN(DURATION) || WAIT > 5000 || DURATION > 10000) {
        debug("WARN: Invalid configuration.", JSON.stringify({ WAIT: WAIT, DURATION: DURATION }));
        return null;
    }
    debug("Going to indicate typing...", JSON.stringify({ WAIT: WAIT, DURATION: DURATION }));
    var TIMER = setTimeout(function () {
        _startIsTypingBot(dispatch, threadId);
        TIMER = null;
    }, WAIT);
    return { STARTED: STARTED, DURATION: DURATION, TIMER: TIMER, getWait: getWait, getDuration: getDuration };
};
/**
 * @param dispatch
 * @param desc
 * @private
 */
var _maybeIsTypingTeardown = function (dispatch, desc) { return __awaiter(void 0, void 0, void 0, function () {
    var STARTED, DURATION, TIMER, elapsedSoFar;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!desc) {
                    return [2 /*return*/];
                }
                STARTED = desc.STARTED, DURATION = desc.DURATION, TIMER = desc.TIMER;
                if (!STARTED || !DURATION) {
                    if (TIMER) {
                        clearTimeout(TIMER);
                    }
                    return [2 /*return*/];
                }
                elapsedSoFar = Date.now() - STARTED;
                if (!(elapsedSoFar < DURATION)) return [3 /*break*/, 2];
                debug("Going to sleep for ".concat(DURATION - elapsedSoFar, "..."));
                return [4 /*yield*/, mmDelay(DURATION - elapsedSoFar)];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2:
                // is typing cleanup
                _stopIsTypingBot(dispatch);
                // ak sme tu a este stale nezbehol timer (nelogicka konfiguracia asi)
                // tak urcite chceme clearnut...
                if (TIMER) {
                    clearTimeout(TIMER);
                }
                return [2 /*return*/];
        }
    });
}); };
