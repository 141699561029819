import * as React from 'react';
import { Button } from 'reactstrap';
import { BubbleCommand } from '../_shared/constants';
import { BubbleChannel } from '../client/src/modules/_core/components/BubbleChannel';
import { HeaderClass } from '../client/src/modules/_core/components/Header/Header';
import { PageHome } from '../client/src/modules/_core/components/PageHome/PageHome';
import { config } from '../client/src/modules/_core/config';
import { url } from '../client/src/modules/_core/url';
import { I18nMsgNs } from '../client/src/modules/_core/utils/i18n-helpers';
import { ChatMessage } from '../client/src/modules/chat/ChatMessage';
import { TextMessageClass } from '../client/src/modules/chat/components/Messages/TextMessage';
import { dataLayerPush } from './utils';
import { onCloseHandler } from './cez-distribuce/externals-fns/header-close-handler';
export var initializeExternals = function () {
    var B = config.css.B('-header');
    var isFullscreen = false;
    ChatMessage.groupLabelResolver = function (msg) {
        if (msg.is_bot)
            return 'other';
        else if (msg.is_agent)
            return 'AGENT';
        else
            return 'user';
    };
    PageHome.getInitialRoute = function () { return url.CHAT(); };
    TextMessageClass.messageTimestampFormatter = function (message, cmp) {
        var d = new Date(message.created);
        var pad = function (v) { return (v < 10 ? "0".concat(v) : v); };
        return pad(d.getHours()) + ':' + pad(d.getMinutes());
    };
    HeaderClass.rendererLeftBox = function (cmp) {
        var toggleFullscreen = function (e) {
            e.preventDefault();
            isFullscreen = !isFullscreen;
            BubbleChannel.postCommand(BubbleCommand.OPEN_FULLSCREEN_TOGGLE);
        };
        return (React.createElement("button", { className: "".concat(B, "__boxbutton fullscreen-icon ").concat(isFullscreen ? 'fullscreen-on' : ''), onClick: toggleFullscreen }));
    };
    HeaderClass.rendererRightBox = function (cmp) {
        var B = config.css.B('-header');
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { className: "".concat(B, "__boxbutton refresh-icon"), onClick: function (e) {
                    e.preventDefault();
                    onCloseHandler(cmp.props.dispatch, function () {
                        cmp.props.history.push(url.HOME());
                    });
                } }),
            React.createElement("button", { className: "".concat(B, "__boxbutton close-icon"), onClick: function (e) {
                    e.preventDefault();
                    onCloseHandler(cmp.props.dispatch, function () {
                        cmp._handleCloseClick(e);
                    });
                } })));
    };
    PageHome.rendererMain = function (cmp) {
        var Msg = I18nMsgNs('PageHome');
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "gaid-page-home__texts" },
                React.createElement("h1", null,
                    React.createElement(Msg, { id: "startWelcome" })),
                React.createElement("p", null,
                    React.createElement(Msg, { id: "startWelcomeDesc" }))),
            React.createElement("div", { className: "gaid-page-home__buttonwrap" },
                React.createElement(Button, { color: "primary", onClick: function () {
                        PageHome.onStartNewClick();
                        // https://docs.google.com/spreadsheets/d/1WDsoIs_v0Dc1sOASo0k0ugUbb2i7o3QaJj6NyZisKAQ/edit#gid=0
                        dataLayerPush({
                            eventAction: 'RedButton',
                            eventLabel: 'Zacat Chat',
                        });
                    } },
                    React.createElement(Msg, { id: "startNew" })))));
    };
};
