import { __assign, __awaiter, __extends, __generator } from "tslib";
import endsWith from 'lodash-es/endsWith';
import { mmUid } from 'mm-ts-utils';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BUBBLE_MSG_TARGET_ORIGIN, BubbleMsg, MESSAGES_ROOM_NAME, } from '../../../../../_shared/constants';
import { ARBITRARY_ID_BUBBLE_COMMAND, ARBITRARY_ID_BUBBLE_UI_STATE, ARBITRARY_ID_INITIAL_DATA, ARBITRARY_ID_UI_STATE, DOMAIN_TYPE_ARBITRARY, } from '../../../consts';
import { domainAction, domainSelector } from '../../domain/domain-redux';
import { url } from '../url';
import { replaceApiAdapterConfigHash } from '../utils/api-helpers';
import { getClientConfig } from '../utils/app-storage';
import { createDebugLogger, parseUrl } from '../utils/app-utils';
// https://www.google.com/search?client=firefox-b-d&q=detect+if+in+iframe
var isInIframe = function () {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return true;
    }
};
var debug = createDebugLogger('BubbleChannel');
var BubbleChannelClass = /** @class */ (function (_super) {
    __extends(BubbleChannelClass, _super);
    function BubbleChannelClass() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.debug = createDebugLogger('BubbleChannel');
        _this.onMessage = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var m, dispatch, receivalPayload, _a;
            var _b, _c, _d, _e, _f, _g;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        m = e.data;
                        dispatch = this.props.dispatch;
                        // we are handling here only 'bubble' namespaced messages
                        if (m.room !== MESSAGES_ROOM_NAME) {
                            return [2 /*return*/];
                        }
                        _a = m.type;
                        switch (_a) {
                            case BubbleMsg.ECHO: return [3 /*break*/, 1];
                            case BubbleMsg.INITIAL_DATA: return [3 /*break*/, 2];
                            case BubbleMsg.NEW_CONFIG_HASH: return [3 /*break*/, 4];
                            case BubbleMsg.BUILDER_SETTINGS: return [3 /*break*/, 5];
                            case BubbleMsg.UI_STATE: return [3 /*break*/, 5];
                            case BubbleMsg.GET_STATE_RESPONSE: return [3 /*break*/, 6];
                        }
                        return [3 /*break*/, 7];
                    case 1:
                        dispatch(domainAction.mergeModelsPayload((_b = {},
                            _b[DOMAIN_TYPE_ARBITRARY] = (_c = {},
                                _c[ARBITRARY_ID_UI_STATE] = {
                                    isBubbleOpen: Boolean(m.payload.isBubbleOpen),
                                },
                                _c),
                            _b)));
                        if (m.payload && BubbleChannelClass._pendingCallbacks.has(m.payload.id)) {
                            BubbleChannelClass._pendingCallbacks.get(m.payload.id)(m.payload.response);
                            BubbleChannelClass._pendingCallbacks.delete(m.payload.id);
                        }
                        return [3 /*break*/, 8];
                    case 2: return [4 /*yield*/, this._onInitialData(m.payload)];
                    case 3:
                        receivalPayload = _h.sent();
                        return [3 /*break*/, 8];
                    case 4:
                        replaceApiAdapterConfigHash(m.payload);
                        window.location.href = url.HOME();
                        return [3 /*break*/, 8];
                    case 5:
                        dispatch(domainAction.mergeModelsPayload((_d = {},
                            _d[DOMAIN_TYPE_ARBITRARY] = (_e = {}, _e[ARBITRARY_ID_UI_STATE] = m.payload, _e),
                            _d)));
                        return [3 /*break*/, 8];
                    case 6:
                        dispatch(domainAction.mergeModelsPayload((_f = {},
                            _f[DOMAIN_TYPE_ARBITRARY] = (_g = {},
                                _g[ARBITRARY_ID_BUBBLE_UI_STATE] = m.payload,
                                _g),
                            _f)));
                        return [3 /*break*/, 8];
                    case 7:
                        this.debug("Ignoring message. No handler for type '".concat(m.type, "'."));
                        _h.label = 8;
                    case 8:
                        // aby sme sa drzali konvencie komunikacneho kanalu, tak notifikujeme
                        // odosielatela, ze sme obdrzali spravu (a mozno aj nejaky payload mu
                        // vraciame)...
                        BubbleChannelClass._confirmReceival(m, receivalPayload);
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    BubbleChannelClass.postMessage = function (payload, type, cb) {
        if (!isInIframe()) {
            return false;
        }
        var id = mmUid();
        if (typeof cb === 'function') {
            BubbleChannelClass._pendingCallbacks.set(id, cb);
        }
        BubbleChannelClass.parentBubblePostMessage({
            id: id,
            type: type,
            payload: __assign(__assign({}, (payload || {})), { timestamp: new Date() }),
        });
    };
    BubbleChannelClass.postCommand = function (command, data, cb) {
        if (!isInIframe()) {
            return false;
        }
        var id = mmUid();
        if (typeof cb === 'function') {
            BubbleChannelClass._pendingCallbacks.set(id, cb);
        }
        BubbleChannelClass.parentBubblePostMessage({
            id: id,
            type: BubbleMsg.COMMAND,
            room: MESSAGES_ROOM_NAME,
            payload: { command: command, data: data, timestamp: new Date() },
        });
    };
    BubbleChannelClass.prototype.componentDidMount = function () {
        // IMPORTANT - return early as NOOP if app is NOT loaded as iframe
        if (!isInIframe()) {
            return;
        }
        this.debug("Enabling bubble channel...");
        window.addEventListener('message', this.onMessage);
        // Let the parent window (bubble) know we're ready. This is important because
        // bubble knows this iframe is loaded and can send initial data here.
        BubbleChannelClass.parentBubblePostMessage({
            type: BubbleMsg.HELLO,
            payload: { timestamp: new Date() },
        });
    };
    BubbleChannelClass.prototype.componentDidUpdate = function (prevProps) {
        var bubbleCommandPayload = this.props.bubbleCommandPayload;
        // IMPORTANT: prevProps.bubbleCommandPayload !== bubbleCommandPayload
        if (bubbleCommandPayload &&
            prevProps.bubbleCommandPayload !== bubbleCommandPayload) {
            var commandPayloads = Array.isArray(bubbleCommandPayload)
                ? bubbleCommandPayload
                : [bubbleCommandPayload];
            commandPayloads.forEach(function (bcp) {
                var command = bcp.command, data = bcp.data;
                BubbleChannelClass.postCommand(command, data);
            });
            // IMPORTANT!
            this.props.dispatch(domainAction.deleteModelByEntityTypeAndId(DOMAIN_TYPE_ARBITRARY, ARBITRARY_ID_BUBBLE_COMMAND));
        }
    };
    BubbleChannelClass.prototype.render = function () {
        return null;
    };
    BubbleChannelClass.parentBubblePostMessage = function (message) {
        var isEcho = message.type === BubbleMsg.ECHO;
        // defaults (for non-ECHOs)
        if (!isEcho) {
            message.id = message.id || mmUid();
        }
        message.room = message.room || MESSAGES_ROOM_NAME;
        !isEcho && debug("--> SEND", message);
        window.parent.postMessage(message, BUBBLE_MSG_TARGET_ORIGIN);
    };
    BubbleChannelClass.prototype._onInitialData = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var whitelist, _clientHostname, _parentHostname_1, isIpLike, isAllowed, dispatch, id;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        whitelist = (getClientConfig().IFRAME_ALLOW_FROM_DOMAIN_WHITELIST || '')
                            .split(',')
                            .map(function (v) { return "".concat(v).trim(); })
                            .filter(function (v) { return !!v; });
                        _clientHostname = parseUrl(null, 'hostname');
                        if (data && data._url && whitelist.length) {
                            _parentHostname_1 = parseUrl(data._url, 'hostname');
                            isIpLike = /^[\d.]+$/.test(_parentHostname_1);
                            // ips, localhost and sameorigin are always allowed
                            if (!isIpLike &&
                                _parentHostname_1 !== 'localhost' &&
                                _parentHostname_1 !== _clientHostname) {
                                isAllowed = whitelist.some(function (domain) {
                                    return endsWith(_parentHostname_1.toLowerCase(), domain.toLowerCase());
                                });
                                if (!isAllowed) {
                                    console.error("NOT ALLOWED: \"".concat(_parentHostname_1, "\" not found in \"").concat(whitelist, "\""));
                                    this.props.history.push(url.NOT_ALLOWED());
                                    return [2 /*return*/];
                                }
                            }
                        }
                        dispatch = this.props.dispatch;
                        id = ARBITRARY_ID_INITIAL_DATA;
                        // tu pouzijeme standarnu "domain model" konvenciu a nase inital data si
                        // ulozime ako domenovy typ "ARBITRARY" a id zaznamu bude "initial_data"
                        dispatch(domainAction.mergeModelsPayload((_a = {},
                            _a[DOMAIN_TYPE_ARBITRARY] = (_b = {}, _b[id] = __assign(__assign({}, data), { id: id }), _b),
                            _a)));
                        if (data && data.bubbleConfiguration) {
                            dispatch(domainAction.mergeModelsPayload((_c = {},
                                _c[DOMAIN_TYPE_ARBITRARY] = (_d = {},
                                    _d[ARBITRARY_ID_UI_STATE] = data.bubbleConfiguration,
                                    _d),
                                _c)));
                        }
                        if (!(typeof BubbleChannelClass.onInitialDataCustomHandler === 'function')) return [3 /*break*/, 2];
                        return [4 /*yield*/, BubbleChannelClass.onInitialDataCustomHandler(this, data)];
                    case 1: return [2 /*return*/, _e.sent()];
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BubbleChannelClass._confirmReceival = function (m, response) {
        if (m.id) {
            debug("--> (Echo: ".concat(m.id, ")"), response);
            BubbleChannelClass.parentBubblePostMessage({
                type: BubbleMsg.ECHO,
                payload: { id: m.id, response: response },
            });
        }
    };
    BubbleChannelClass._pendingCallbacks = new Map();
    return BubbleChannelClass;
}(React.Component));
export { BubbleChannelClass };
var mapStateToProps = function (state, ownProps) {
    var bubbleCommandMessage = domainSelector.getByTypeAndId(state)(DOMAIN_TYPE_ARBITRARY, ARBITRARY_ID_BUBBLE_COMMAND);
    return {
        bubbleCommandPayload: (bubbleCommandMessage || {}).payload,
    };
};
var mapDispatchToProps = function (dispatch, ownProps) {
    return ({
        dispatch: dispatch,
    });
};
export var BubbleChannel = withRouter(connect(mapStateToProps, mapDispatchToProps)(BubbleChannelClass));
