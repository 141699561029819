import omit from 'lodash-es/omit';
export var getMeta = function (state) {
    var _a;
    var pmaSavedMessages = Object.keys(state.chat.pmaSaveMessage).length === 0 ? state.chat.pmaCreateThread : state.chat.pmaSaveMessage;
    var normalizedPmaSavedMessages = omit(pmaSavedMessages, ['__EMPTY_ID__']);
    // Pick the last saved message
    var lastSavedMessage = normalizedPmaSavedMessages[Object.keys(normalizedPmaSavedMessages)[0]];
    for (var _i = 0, _b = Object.keys(normalizedPmaSavedMessages); _i < _b.length; _i++) {
        var key = _b[_i];
        var pmaSavedMessage = normalizedPmaSavedMessages[key];
        if (pmaSavedMessage.timestamp > lastSavedMessage.timestamp) {
            lastSavedMessage = pmaSavedMessage;
        }
    }
    return ((_a = lastSavedMessage === null || lastSavedMessage === void 0 ? void 0 : lastSavedMessage.payload) === null || _a === void 0 ? void 0 : _a.__meta__) || {};
};
