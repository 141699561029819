export var HTTP_TIMEOUT_IN_MILLIS = Number(process.env.HTTP_TIMEOUT_IN_MILLIS || 240000);
export var IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export var IS_PRODUCTION = process.env.NODE_ENV === 'production';
export var CLIENT_CONFIG_EXPOSE_KEY = '__GAID_CLIENT_CONFIG__';
export var APIADAPTER_URL_MAP_EXPOSE_KEY = '__GAID_CHAT_APIADAPTER_URL_MAP__';
export var APIADAPTER_FACTORY_EXPOSE_KEY = '__GAID_CHAT_APIADAPTER__';
export var ENTITY_TYPE_USER = 'user';
export var ENTITY_TYPE_CHAT_THREAD = 'chat_thread';
export var ENTITY_TYPE_CHAT_MESSAGE = 'chat_message';
export var DOMAIN_TYPE_CHAT_THREAD = ENTITY_TYPE_CHAT_THREAD;
export var DOMAIN_TYPE_USER = ENTITY_TYPE_USER;
export var DOMAIN_TYPE_CHAT_MESSAGE = ENTITY_TYPE_CHAT_MESSAGE;
export var DOMAIN_TYPE_CLIENT_NOTIFICATION = 'client_notification';
export var DOMAIN_TYPE_OPPOSITE_PARTY = 'is_opposite_party_agent';
export var DOMAIN_TYPE_ARBITRARY = 'arbitrary';
export var ARBITRARY_ID_INITIAL_DATA = 'initial_data';
export var ARBITRARY_ID_UI_STATE = 'ui_state';
export var ARBITRARY_ID_BUBBLE_UI_STATE = 'bubble_ui_state';
export var ARBITRARY_ID_APP_MODAL = 'app_modal';
export var ARBITRARY_ID_BUBBLE_COMMAND = 'bubble_command';
export var NOTIFICATION_ID__THREAD_CREATED = 'thread_created';
export var NOTIFICATION_ID__THREAD_CHANGE = 'thread_change';
export var NOTIFICATION_ID__THREAD_IS_TYPING = 'thread_is_typing';
// defaultny render...
export var CHAT_MESSAGE_DISPLAY_CONTEXT_DEFAULT = 'default';
// render izolovany message, typicky v PageMessage
export var CHAT_MESSAGE_DISPLAY_CONTEXT_STANDALONE = 'standalone';
// ugly hack uz neviem na co... tusim nieco pre prepaid telekom
export var BODY_CLEAR_CONTENT_MARKER = 'Začít chat';
/**
 * This ID is used to distinguish messages from user and GoodBot.
 * See the {@link BaseChatMessage.is_bot} and its usage.
 * The more intuitive field `is_bot` is ignored because it has only two states
 * and can't distinguish more actors in the chat (user, goodbot, agent, widget).
 * See the discussion on link below for more info.
 * https://bitbucket.org/goodaidigital/standalone-client/pull-requests/41/the-messages-ordering-breaks-in-bubble#comment-138961549
 * Both fields are set in the {@link DOMAIN_TYPE_CHAT_MESSAGE} objects to be sure
 * it doesn't break by any other change in the code.
 */
export var GOODBOT_USER_ID = '11111111-1111-1111-1111-111111111111';
/**
 * Podobne ako vyssie, potrebujeme nejak rozslisit ci komunikujeme s agentom.
 * Zial `type` je historicky nepouzitelny, preto to skusame nejak obist custom ideckom,
 * z ktoreho to budeme vediet zistit.
 */
export var AGENT_USER_ID = '11111111-2222-3333-4444-555555555555';
export var CUSTOM_MESSAGE_TYPE_MARKER = '__custom__';
export var CUSTOM_MESSAGE_ROOM_MARKER__SET_ENTITIES = '__set_entities__';
export var CUSTOM_MESSAGE_ROOM_MARKER__SET_STATE_AND_ENTITIES = '__set_state_and_entities__';
export var CUSTOM_MESSAGE_ROOM_MARKER__SET_STATE = '__set_state__';
export var CUSTOM_MESSAGE_NEXT_INPUT_KEY = '__next_input__';
export var CUSTOM_MESSAGE_NEXT_INPUT_VALUE_FORCE_NONE = '__force_no_next_input__';
// for simplicity, we'll distinguish only two states "regular" and "small"
export var SCREEN_SMALL_WIDTH_BREAKPOINT_PX = 767;
export var DUMMY_AUDIO = 'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
